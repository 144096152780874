.default-table-container {
  width: 95%;
  .default-table-list-partner {
      max-height: 95%;
      overflow: visible;
      .nr-cell {
          min-width: 40px;
          padding-left: 10px;
          text-align: left;
      }
      .partner-name-cell {
          min-width: 150px;
          padding-left: 0px;
          position: sticky;
          left: 0;
          z-index: 10;
      }
      .location-cell {
          min-width: calc(150px + 30px + 10px);
          padding-left: 30px;
          padding-right: 10px;
          position: sticky;
          left: 150px;
          z-index: 10;
          box-shadow: 1px 0 0 0 #DDDDDD;
      }
      .partner-since-cell {
          padding-left: 20px;
          margin-right: 50px;
          min-width: 170px;
          text-align: left;
      }
      .table-list-head-cell {
        padding-top: 8px;
        padding-bottom: 8px;
      }

      .divider-cell {
        min-width: 30px;
      }
      .rate-cell {
        min-width: 110px;
        padding-left: 10px;
    }
    .table-list-head {
        background-color: $background;
        text-transform: uppercase;
        position: sticky;
        position: -webkit-sticky;
        top: 0;
        z-index: 1;
        border-bottom: 1px solid #DDDDDD;

        .table-list-head-cell{
            background-color: $background;
            color: $textcolor;
            border: 0;
            font-size: 12px;
            font-weight: $font-weight-bold;
          }
      }
    .partner-list-main-table {
        display: block;
        width: 100%;

        .table-list-head {
            display: block;
            width: 100%;
            z-index: 20;
        }
        .default-table-body {
            display: block;
            overflow: auto;
        }

        .scroller {
            width: 100%;
            display: block;
            overflow: hidden;
        }

        .table-fullwidth-row:hover {
            background-color: #EFEFEF;
            transition: .3s;

            .cell-background {
                background-color: #EFEFEF;
            }
        }

        .cell-background {
            background-color: #FFFFFF;
            position: absolute;
            display: block;
            width: 100%;
            height: calc(100% - 8px);
            bottom: 0;
            left: 0;
            transition: all 0.2s ease-out;
        }
    }
  }
  .default-table-body {
      .table-fullwidth-row {
          height: 60px;
          @include table-tr-style();
          @include transition();
      }
  }
}
