.weekly-record-grid-style {
    margin-top: 3%;
    .week-nr-col {
        @include week-nr();
        margin-top: 20%;
    }
    .warehouse-name {
        font-weight: $font-weight-bold;
        font-size: 16px;
        display: flex;
        align-items: center;
    }
    .dot {
        height: 10px;
        width: 10px;
        background-color: #FF8A00;
        border-radius: 50%;
        display: inline-block;
        margin: 0px 10px 0px 5px;
    }
    .dot-inactive {
        height: 10px;
        width: 10px;
        background-color: #B8B8B8;
        border-radius: 50%;
        display: inline-block;
        margin: 0px 10px 0px 5px;
    }
    .weekly-record-divider {
        margin: 0px 20px 5px 15px;
    }
    .weekly-record-table, .date-range {
        font-size: 16px;
        font-weight: $font-weight-regular;
    }
    .date-range {
        margin: 10px 0;
    }
    .weekly-record-header-section {
        .warehouse-col {
            display: flex;
            align-items: center;
            justify-content: space-between;
            font-size: 12px;
            text-transform: uppercase;
            font-weight: $font-weight-bold;
            margin-left: 15px;
            margin-right: 15px;
        }
    }
    .dot-tip-text {
        display: flex;
        align-items: center;
        justify-content: space-around;
    }
    .dot-tip {
        height: 10px;
        width: 10px;
        background-color: #FF8A00;
        border-radius: 50%;
        display: inline-block;
        margin-left: 20px;
        margin-right: 5px;
    }
    .dot-inactive-tip {
        height: 10px;
        width: 10px;
        background-color: #B8B8B8;
        border-radius: 50%;
        display: inline-block;
        margin-left: 20px;
        margin-right: 5px;
    }
    .weekly-record-action-buttons {
        display: flex;
        justify-content: end;
        align-items: center;
        gap: 10px;
    }
}

.xmark {
    position: absolute;
    right: 0;
    top: 0;
    padding: 20px;
    cursor: pointer;
}

.settings-gear {
    cursor: pointer;
}

.popUpContainer {
    width: 95%;
    display: flex;
    align-items: center;
    flex-direction: column;
    justify-content: space-between;
    padding: 50px;

    .title-container {
        display: flex;
        align-items: center;
        flex-direction: row;
        justify-content: space-between;
        width: 70%;

        .xmark {
            size: 25px;
        }

        .title {
            font-size: 16px;
            font-weight: $font-weight-bold;
            color: $textcolor;
            text-transform: uppercase;
        }

        .sub-title {
            font-size: 11px;
            letter-spacing: 3px;
            font-weight: $font-weight-bold;
            color: $textcolor;
            text-transform: uppercase;
        }
    }

    .weekly-record-send-message {
        margin-top: 25px;
    }

    .weekly-record-send-text-emails,
    .weekly-record-send-text-partner,
    .weekly-record-send-text {
        margin-top: 10px;
    }

    .weekly-record-send-text-emails {
        color: $generalblue;
    }

    .weekly-record-send-text-partner {
        font-weight: $font-weight-regular;
        margin-bottom: 16px;
    }

    .add-email-textfield-container {
        display: flex;
        flex-direction: row;
        align-items: center;
        justify-content: space-around;
        margin-bottom: 16px;
        gap: 5px;
        width: 70%;

        .email-textfield {
            width: 100%;
            height: 50px;
            padding-left: 10px;
            
        }
        .email-textfield:focus {
            border-color: $generalblue;
        }
        .remove-email-button {
            background-color: transparent;
            border: none;
        }
        *:focus {
            outline: none;
        }
    }

    .setting-modal-button-container {
        width: 70%;
        display: flex;
        justify-content: space-between;
    }

    .button {
        margin-top: 25px;
        height: 40px;
        width: 50px;
        border-radius: 2%;
        border-width: 0;
        color: white;
        background-color: $generalblue;
        font-size: small;
    }

    .button--add-email {
        width: fit-content;

        &:disabled {
            opacity: 0.75;
        }
    }

    .text-field-title-placeholder {
        margin: 5px;
    }

    .weekly-record-send-button-container {
        display: flex;
        flex-direction: row;
        align-items: center;
        justify-content: center;

        .weekly-record-send-button-no,
        .weekly-record-send-button-yes,
        .weekly-record-send-button {
            margin: 10px;
            height: 40px;
            width: 50px;
            border-radius: 2%;
            border-width: 0;
            color: white;
            font-size: small;
        }

        .weekly-record-send-button-no {
            background-color: red;
        }

        .weekly-record-send-button-yes {
            background-color: green;
        }
    }
}
